@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,200;0,300;0,400;0,500;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

:root {
  /* fonts */
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-13xl: 32px;
  --font-size-45xl: 64px;
  --font-size-xl: 20px;
  --font-size-12xl-7: 31.7px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-mini: 15px;
  --font-size-xs: 12px;

  /* Colors */
  --not-black-black: #2d2d2d;
  --yellow: #fdc534;
  --white: #fff;
  --blue: #1daeff;
  --color-deepskyblue-100: rgba(29, 174, 255, 0);
  --color-gray-100: #818181;
  --color-typography-title: #120d26;
  --color-gray-200: rgba(255, 255, 255, 0.81);
  --color-darkgray-100: #a2a2a2;
  --color-darkgray: #9d9898;
  --color-gainsboro: #e4dfdf;
  --deactivated-grey-color: #d6dbde;
  --color-mediumslateblue: #5669ff;
  --color-typography-sub-color: #747688;
  --bubble-blue: #b3d8fb;

  /* Gaps */
  --gap-102xl: 121px;
  --gap-141xl: 160px;
  --gap-4xl: 23px;
  --gap-xl: 20px;
  --gap-lgi-7: 19.7px;
  --gap-65xl: 84px;
  --gap-sm: 14px;
  --gap-5xs: 8px;
  --gap-54xl: 73px;
  --gap-10xs: 3px;
  --gap-7xs: 6px;
  --gap-8xs: 5px;
  --gap-xs: 12px;
  --gap-9xs: 4px;
  --gap-46xl: 65px;
  --gap-51xl: 70px;
  --gap-11xl: 30px;
  --gap-67xl: 86px;
  --gap-2xs: 11px;
  --gap-lg: 18px;
  --gap-16xl: 35px;
  --gap-3xl: 22px;

  /* Paddings */
  --padding-71xl: 90px;
  --padding-96xl: 115px;
  --padding-113xl: 132px;
  --padding-xl: 20px;
  --padding-31xl: 50px;
  --padding-3xs: 10px;
  --padding-3xl: 22px;
  --padding-9xs: 4px;
  --padding-17xl: 36px;
  --padding-46xl: 65px;
  --padding-2xs: 11px;
  --padding-lg: 18px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-226xl-7: 245.7px;
  --br-7xs: 6px;
  --br-5xs: 8px;
  --br-mini: 15px;
  --br-34xl: 53px;
  --br-47xl: 66px;
  --br-3xs: 10px;
  --br-6xl-4: 25.4px;
  --br-6xl-9: 25.9px;
  --br-11xs-3: 1.3px;
  --br-base-6: 15.6px;
  --br-6xs: 7px;
  --br-xs: 12px;

  /* Effects */
  --box-shadow: 0px 1.1250001192092896px 3.38px rgba(0, 0, 0, 0.15);
  --webite-tab-shadow-1: 4px 3px 3px rgba(255, 255, 255, 0.28) inset,
    -4px -3px 3px rgba(255, 255, 255, 0.28) inset;
  --blue-bubble-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  --tab-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
}

body {
  margin: 0;
  line-height: normal;
  font-family: var(--font-roboto);
  min-height: -webkit-fill-available;
  /* max-width: 1512px; */
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-family: Montserrat;
}

input {
  border: none;
  outline: none;
  font-family: Montserrat;
}

textarea {
  border: none;
  outline: none;
  font-family: Montserrat;
}
